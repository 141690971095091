define("ogn-web-viewer/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AuuvQil0",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[4,\"if\",[[24,[\"showDialog\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"dialog\"],[[\"from\"],[\"ogn-web-viewer/styles/error\"]]]]]],[13,1],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"title\"],[[\"from\"],[\"ogn-web-viewer/styles/error\"]]]]]],[8],[1,[28,\"t\",[\"error-dialog.title\"],null],false],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"error\",\"name\"]],\"InvalidTaskError\"],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"error-dialog.invalid-task-error\"],[[\"url\",\"cause\"],[[23,0,[\"error\",\"url\"]],[23,0,[\"error\",\"cause\",\"message\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"fetchResponse\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"error-dialog.loading-url-failed\"],[[\"url\",\"status\",\"statusText\"],[[23,0,[\"fetchResponse\",\"url\"]],[23,0,[\"fetchResponse\",\"status\"]],[23,0,[\"fetchResponse\",\"statusText\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,0,[\"error\",\"message\"]],false],[0,\"\\n      \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"button\",false],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showDialog\"]],false],null]]],[8],[0,\"\\n      Close\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ogn-web-viewer/templates/error.hbs"
    }
  });

  _exports.default = _default;
});