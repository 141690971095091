define("ogn-web-viewer/transforms/strepla-duration", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;
  const RE_DURATION = /^(\d+):([0-5]\d)$/;
  /**
   * Parses strings like "02:34" into `154` (2 hours + 34 minutes).
   */

  class _default extends Transform {
    deserialize(serialized) {
      if (!serialized) return null;
      if (typeof serialized !== 'string') throw new Error(`Invalid duration: ${serialized}`);
      let match = serialized.match(RE_DURATION);
      if (!match) throw new Error(`Invalid duration: ${serialized}`);
      let hh = parseInt(match[1], 10);
      let mm = parseInt(match[2], 10);
      return hh * 60 + mm;
    }

  }

  _exports.default = _default;
});