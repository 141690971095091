define("ogn-web-viewer/styles/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "overlay-shadow": "0 3px 6px hsla(0, 0%, 0%, .15), 0 2px 4px hsla(0, 0%, 0%, .12)",
    "dialog": "_dialog_1m2gu2",
    "title": "_title_1m2gu2"
  };
  _exports.default = _default;
});