define("ogn-web-viewer/utils/timeout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = timeout;

  /**
   * Alternative to the `timeout()` function from `ember-concurrency` that
   * does not use the Ember runloop. By not using the Ember runloop it causes
   * it to not block any async tests.
   */
  async function timeout(duration) {
    return new Promise(resolve => setTimeout(resolve, duration));
  }
});