define("ogn-web-viewer/adapters/strepla-competition", ["exports", "ember-data", "ember-data-strepla/urls", "ogn-web-viewer/adapters/-base"], function (_exports, _emberData, _urls, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    NotFoundError
  } = _emberData.default;

  class _default extends _base.default {
    async findRecord(store, type, id) {
      (false && !(type.modelName === 'strepla-competition') && Ember.assert('Query `type` must be `strepla-competition`', type.modelName === 'strepla-competition'));
      let competitions = await this._query();
      let competition = competitions.find(it => String(it.id) === id);

      if (!competition) {
        competitions = await this._query({
          daysPeriod: 365
        });
        competition = competitions.find(it => String(it.id) === id);
      }

      if (!competition) {
        competitions = await this._query({
          daysPeriod: 100000
        });
        competition = competitions.find(it => String(it.id) === id);
      }

      if (!competition) {
        throw new NotFoundError(null, `Competition with ID ${id} could not be found`);
      }

      return competition;
    }

    async query(store, type, query) {
      (false && !(type.modelName === 'strepla-competition') && Ember.assert('Query `type` must be `strepla-competition`', type.modelName === 'strepla-competition'));
      return await this._query(query);
    }

    async _query({
      daysPeriod
    } = {}) {
      let url = _urls.COMPETITION_LIST_URL;

      if (daysPeriod) {
        url += `&daysPeriod=${daysPeriod}`;
      }

      return await this._request(url);
    }

  }

  _exports.default = _default;
});