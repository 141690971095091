define("ogn-web-viewer/utils/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toDateString = toDateString;

  function toDateString(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return [year, month.toString().padStart(2, '0'), day.toString().padStart(2, '0')].join('-');
  }
});