define("ogn-web-viewer/routes/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    setupController(controller, error) {
      controller.setError(error);
    }

  }

  _exports.default = _default;
});