define("ogn-web-viewer/serializers/strepla-task", ["exports", "ogn-web-viewer/serializers/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _base.default {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      (false && !(['queryRecord'].indexOf(requestType) !== -1) && Ember.assert(`Unknown \`requestType\`: ${requestType}`, ['queryRecord'].indexOf(requestType) !== -1));
      return this.normalize(primaryModelClass, payload);
    }

    normalize(modelClass, resourceHash) {
      let data = {
        id: resourceHash.id,
        type: modelClass.modelName,
        attributes: {
          name: resourceHash.name,
          distance: resourceHash.distance,
          numLegs: resourceHash.numLegs,
          ruleName: resourceHash.rule,
          minTime: resourceHash.min_time || null,
          turnpoints: resourceHash.tps
        },
        relationships: {
          competition: {
            data: {
              id: resourceHash.competitionId,
              type: 'strepla-competition'
            }
          },
          competitionDay: {
            data: {
              id: resourceHash.competitionDayId,
              type: 'strepla-competition-day'
            }
          }
        }
      };
      this.applyTransforms(modelClass, data.attributes);
      return {
        data,
        included: []
      };
    }

  }

  _exports.default = _default;
});