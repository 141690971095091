define("ogn-web-viewer/components/aircraft-shadow-layer", ["exports", "@glimmer/component", "ol/layer/Vector", "ol/style", "ogn-web-viewer/components/aircraft-layer"], function (_exports, _component, _Vector, _style, _aircraftLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let _class = (_dec = Ember.inject.service('map'), _dec2 = Ember.computed.alias('mapService.map'), (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "aircraft", _descriptor, this);

      _initializerDefineProperty(this, "ddb", _descriptor2, this);

      _initializerDefineProperty(this, "filter", _descriptor3, this);

      _initializerDefineProperty(this, "mapService", _descriptor4, this);

      _initializerDefineProperty(this, "map", _descriptor5, this);

      this._iconStyles = new WeakMap();
      this.layer = new _Vector.default({
        source: this.aircraft.source,
        opacity: 0.2,
        maxResolution: 500,
        style: (...args) => this._getFeatureStyle(...args)
      });
      this.map.addLayer(this.layer);
    }

    willDestroy() {
      this.map.removeLayer(this.layer);
      super.willDestroy();
    }

    _getFeatureStyle(feature) {
      let {
        id
      } = feature.getProperties();
      let device = this.ddb.devices[id] || {};
      let filterRow = this.filter.filter.find(row => row.id === id) || {};
      let hasFilter = this.filter.filter.length !== 0;
      let isFiltered = hasFilter && !filterRow.id;

      if (isFiltered) {
        return [];
      }

      let imageSrc = (0, _aircraftLayer.imageSrcForDevice)(device);

      let style = this._iconStyles.get(feature);

      if (!style || style.getImage().getSrc() !== imageSrc) {
        style = new _style.Style({
          image: new _style.Icon({
            src: imageSrc,
            rotateWithView: true
          })
        });

        this._iconStyles.set(feature, style);
      }

      let {
        course,
        altitude
      } = feature.getProperties();
      let rotation = course * (Math.PI / 180);
      let sin = Math.sin(rotation);
      let cos = Math.cos(rotation);
      let shadowDistance = Math.min(0.2, altitude / 10000);
      let anchor = [0.5 - shadowDistance * sin, 0.5 - shadowDistance * cos];
      let icon = style.getImage();
      icon.setRotation(rotation);
      icon.setAnchor(anchor);
      return style;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "aircraft", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "ddb", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "filter", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "mapService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "map", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});