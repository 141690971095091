define("ogn-web-viewer/services/history", ["exports", "ember-fetch/ajax", "ogn-web-viewer/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Service {
    constructor(...args) {
      super(...args);
      this._byId = {};
    }

    async loadForIds(...ids) {
      let after = Math.round(Date.now() / 1000) - 8 * 60 * 60;
      let idList = encodeURIComponent(ids.join(','));
      let url = `${_environment.default.API_HOST}/api/records/${idList}?after=${after}`;
      let data = await (0, _ajax.default)(url);

      for (let id of ids) {
        let list = data[id] || [];
        this.addRecords(id, list.map(row => {
          let fields = row.split('|');
          return {
            time: parseInt(fields[0], 10) * 1000,
            coordinate: [parseFloat(fields[1]), parseFloat(fields[2])],
            valid: true,
            altitude: parseInt(fields[3], 10)
          };
        }));
      }
    }

    forId(id) {
      let old = this._byId[id];

      if (!old) {
        old = [];
        this._byId[id] = old;
      }

      return old;
    }

    addRecords(id, records) {
      let array = this.forId(id);
      array.push(...records);
      array.sort((a, b) => a.time - b.time);
    }

  }

  _exports.default = _default;
});