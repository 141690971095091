define("ogn-web-viewer/adapters/strepla-task", ["exports", "ember-data-strepla/urls", "ogn-web-viewer/adapters/-base"], function (_exports, _urls, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _base.default {
    async queryRecord(store, type, query) {
      (false && !(type.modelName === 'strepla-task') && Ember.assert('Query `type` must be `strepla-task`', type.modelName === 'strepla-task'));
      let {
        competitionId,
        competitionDayId
      } = query;
      (false && !(competitionId) && Ember.assert('Query must have a `competitionId` property', competitionId));
      (false && !(competitionDayId) && Ember.assert('Query must have a `competitionDayId` property', competitionDayId));
      let task = await this._request(`${_urls.TASK_URL}&cID=${competitionId}&idDay=${competitionDayId}`);
      return { ...task,
        competitionId,
        competitionDayId
      };
    }

  }

  _exports.default = _default;
});