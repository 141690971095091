define("ogn-web-viewer/components/task-layer", ["exports", "@glimmer/component", "ol", "ol/geom", "ol/layer/Vector", "ol/proj", "ol/source/Vector", "ol/style", "ogn-web-viewer/geojson-converter"], function (_exports, _component, _ol, _geom, _Vector, _proj, _Vector2, _style, _geojsonConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  const EPSG_4326 = 'EPSG:4326';
  const EPSG_3857 = 'EPSG:3857';
  const TASK_LEGS_STYLE = new _style.Style({
    stroke: new _style.Stroke({
      color: '#5590ff',
      width: 3
    })
  });
  const TASK_AREA_STYLE = new _style.Style({
    stroke: new _style.Stroke({
      color: '#5590ff',
      width: 3
    }),
    fill: new _style.Fill({
      color: '#5590ff22'
    })
  });

  let _class = (_dec = Ember.inject.service('map'), _dec2 = Ember.computed.alias('mapService.map'), _dec3 = Ember.computed('args.task'), (_class2 = (_temp = class _class2 extends _component.default {
    get source() {
      let taskSource = new _Vector2.default({
        features: []
      });

      if (this.args.task) {
        let legsFeature = new _ol.Feature({
          geometry: new _geom.LineString(this.args.task.points.map(pt => (0, _proj.transform)(pt.shape.center, EPSG_4326, EPSG_3857)))
        });
        legsFeature.setId('legs');
        taskSource.addFeature(legsFeature);
        let areas = this.args.task.points.map(pt => _geojsonConverter.default.readFeature(pt.shape.toGeoJSON()));
        taskSource.addFeatures(areas);
      }

      return taskSource;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "mapService", _descriptor, this);

      _initializerDefineProperty(this, "map", _descriptor2, this);

      this.layer = new _Vector.default({
        id: 'task',
        source: this.source,

        style(feature) {
          let id = feature.getId();
          return id === 'legs' ? TASK_LEGS_STYLE : TASK_AREA_STYLE;
        }

      });
      this.map.addLayer(this.layer);
    }

    willDestroy() {
      this.map.removeLayer(this.layer);
      super.willDestroy();
    }

    updateSource() {
      this.layer.setSource(this.source);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "mapService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "map", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "source", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "source"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateSource", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateSource"), _class2.prototype)), _class2));

  _exports.default = _class;
});