define("ogn-web-viewer/components/aircraft-layer", ["exports", "@glimmer/component", "ol/layer/Vector", "ol/style"], function (_exports, _component, _Vector, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.imageSrcForDevice = imageSrcForDevice;
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  const REGULAR_OPACITY = 1.0;
  const FILTERED_OPACITY = 0.3;

  let _class = (_dec = Ember.inject.service('map'), _dec2 = Ember.computed.alias('mapService.map'), (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "aircraft", _descriptor, this);

      _initializerDefineProperty(this, "ddb", _descriptor2, this);

      _initializerDefineProperty(this, "filter", _descriptor3, this);

      _initializerDefineProperty(this, "mapService", _descriptor4, this);

      _initializerDefineProperty(this, "map", _descriptor5, this);

      this._iconStyles = new WeakMap();
      this._labelStyles = new WeakMap();
      this.layer = new _Vector.default({
        source: this.aircraft.source,
        style: (...args) => this._getFeatureStyle(...args)
      });
      this.map.addLayer(this.layer);
    }

    willDestroy() {
      this.map.removeLayer(this.layer);
      super.willDestroy();
    }

    _getFeatureStyle(feature, resolution) {
      let {
        course,
        id,
        altitude
      } = feature.getProperties();
      let device = this.ddb.devices[id] || {};
      let imageSrc = imageSrcForDevice(device);

      let style = this._iconStyles.get(feature);

      if (!style || style.getImage().getSrc() !== imageSrc) {
        style = new _style.Style({
          image: new _style.Icon({
            src: imageSrc,
            rotateWithView: true
          })
        });

        this._iconStyles.set(feature, style);
      }

      let labelStyle = this._labelStyles.get(feature);

      if (!labelStyle) {
        labelStyle = new _style.Style({
          text: new _style.Text({
            font: '14px sans-serif',
            stroke: new _style.Stroke({
              color: '#fff',
              width: 3
            }),
            textAlign: 'left',
            offsetX: 25
          })
        });

        this._labelStyles.set(feature, labelStyle);
      }

      let filterRow = this.filter.filter.find(row => row.id === id) || {};
      let hasFilter = this.filter.filter.length !== 0;
      let isFiltered = hasFilter && !filterRow.id;
      let rotation = course * (Math.PI / 180);
      style.getImage().setRotation(rotation);
      style.getImage().setOpacity(isFiltered ? FILTERED_OPACITY : REGULAR_OPACITY);

      if (isFiltered) {
        return [style];
      }

      let labelParts = [filterRow.callsign || filterRow.registration || device.callsign || device.registration];

      if (resolution < 100) {
        labelParts.push(`${altitude}m`);
      }

      labelStyle.getText().setText(labelParts.filter(Boolean).join('\n'));
      return [style, labelStyle];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "aircraft", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "ddb", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "filter", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "mapService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "map", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;

  /*
   * `category` means:
   *
   * 1 => Gliders/Motorgliders
   * 2 => Planes
   * 3 => Ultralights
   * 4 => Helicoters
   * 5 => Drones/UAV
   * 6 => Others
   */
  function imageSrcForDevice(device) {
    if (device.registration) {
      if (device.registration.startsWith('D-H')) {
        return '/aircraft/ec135.svg';
      }
    }

    if (device.category === 2 || device.category === 3) {
      return '/aircraft/dr400.svg';
    }

    if (device.category === 4) {
      return '/aircraft/ec135.svg';
    }

    if (device.category === 1) {
      if (device.model) {
        if (device.model.includes('Libelle')) {
          return '/aircraft/libelle.svg';
        } else if (device.model.includes('Hornet')) {
          return '/aircraft/hornet.svg';
        } else if (device.model.includes('Kestrel')) {
          return '/aircraft/kestrel.svg';
        }
      }

      return '/aircraft/duo.svg';
    }

    return '/aircraft/duo.svg';
  }
});