define("ogn-web-viewer/templates/strepla-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lCf2FFEX",
    "block": "{\"symbols\":[],\"statements\":[[5,\"notification\",[[12,\"class\",\"top-centered\"]],[[\"@text\"],[[28,\"if\",[[28,\"media\",[\"isMobile\"],null],\"Loading...\",\"Loading task and competitor list...\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ogn-web-viewer/templates/strepla-loading.hbs"
    }
  });

  _exports.default = _default;
});