define("ogn-web-viewer/geojson-converter", ["exports", "ol/format/GeoJSON"], function (_exports, _GeoJSON) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const geoJson = new _GeoJSON.default({
    featureProjection: 'EPSG:3857'
  });
  var _default = geoJson;
  _exports.default = _default;
});