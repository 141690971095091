define("ogn-web-viewer/templates/strepla/competition/class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c4DppMn4",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[24,[\"model\",\"name\"]]],null],false],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ogn-web-viewer/templates/strepla/competition/class.hbs"
    }
  });

  _exports.default = _default;
});