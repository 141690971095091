define("ogn-web-viewer/components/map", ["exports", "@glimmer/component", "ol/proj"], function (_exports, _component, _proj) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  const EPSG_4326 = 'EPSG:4326';
  const EPSG_3857 = 'EPSG:3857';

  let _class = (_dec = Ember.inject.service('map'), _dec2 = Ember.computed.alias('filter.hasFilter'), _dec3 = Ember.computed.alias('mapService.map'), (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ddb", _descriptor, this);

      _initializerDefineProperty(this, "filter", _descriptor2, this);

      _initializerDefineProperty(this, "history", _descriptor3, this);

      _initializerDefineProperty(this, "ws", _descriptor4, this);

      _initializerDefineProperty(this, "mapService", _descriptor5, this);

      _initializerDefineProperty(this, "hasDeviceFilter", _descriptor6, this);

      _initializerDefineProperty(this, "map", _descriptor7, this);

      this.map.on('moveend', () => {
        if (!this.filter.hasFilter) {
          this.ws.setBBox(this.getBBox());
        }
      });
      this.ws.on('record', this, 'handleRecord');
    }

    willDestroy() {
      this.ws.off('record', this, 'handleRecord');
      super.willDestroy(...arguments);
    }

    handleRecord(record) {
      if (this.filter.hasFilter) {
        this.history.addRecords(record.id, [{
          time: record.timestamp * 1000,
          coordinate: [record.longitude, record.latitude],
          valid: true,
          altitude: record.altitude
        }]);
      }
    }

    getBBox() {
      let extent = this.map.getView().calculateExtent();
      return (0, _proj.transformExtent)(extent, EPSG_3857, EPSG_4326);
    }

    setMapTarget(element) {
      this.map.setTarget(element);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ddb", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "filter", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "history", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "ws", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "mapService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "hasDeviceFilter", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "map", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "setMapTarget", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "setMapTarget"), _class2.prototype)), _class2));

  _exports.default = _class;
});