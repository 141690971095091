define("ogn-web-viewer/services/aircraft", ["exports", "ol/Feature", "ol/geom/Point", "ol/proj", "ol/source/Vector"], function (_exports, _Feature, _Point, _proj, _Vector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  const EPSG_4326 = 'EPSG:4326';
  const EPSG_3857 = 'EPSG:3857';

  let _class = (_class2 = (_temp = class _class2 extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ws", _descriptor, this);

      this.source = new _Vector.default({
        features: []
      });
    }

    init() {
      super.init(...arguments);
      this.ws.on('record', this, 'handleRecord');
    }

    willDestroy() {
      this.ws.off('record', this, 'handleRecord');
      super.willDestroy(...arguments);
    }

    handleRecord(record) {
      let geometry = new _Point.default((0, _proj.transform)([record.longitude, record.latitude], EPSG_4326, EPSG_3857));
      let feature = this.source.getFeatureById(record.id);

      if (feature) {
        let props = feature.getProperties();
        if (props.timestamp >= record.timestamp) return;
        feature.setGeometry(geometry);
      } else {
        feature = new _Feature.default(geometry);
        feature.setId(record.id);
        this.source.addFeature(feature);
      }

      feature.setProperties(record);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ws", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);

  _exports.default = _class;
});