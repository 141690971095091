define("ogn-web-viewer/templates/components/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t8TQmO1h",
    "block": "{\"symbols\":[\"@task\"],\"statements\":[[7,\"div\",false],[12,\"id\",\"map\"],[12,\"style\",[29,[\"width: 100%; height: \",[28,\"if\",[[28,\"and\",[[24,[\"hasDeviceFilter\"]],[23,1,[]]],null],\"70%\",\"100%\"],null]]]],[3,\"did-insert\",[[23,0,[\"setMapTarget\"]]]],[3,\"will-destroy\",[[28,\"fn\",[[23,0,[\"setMapTarget\"]],null],null]]],[8],[0,\"\\n  \"],[5,\"task-layer\",[],[[\"@task\"],[[23,1,[]]]]],[0,\"\\n\\n  \"],[5,\"aircraft-shadow-layer\",[],[[],[]]],[0,\"\\n  \"],[5,\"aircraft-layer\",[],[[],[]]],[0,\"\\n\\n\"],[4,\"if\",[[28,\"not\",[[28,\"media\",[\"isStandalone\"],null]],null]],null,{\"statements\":[[0,\"    \"],[5,\"fullscreen-button\",[],[[\"@map\"],[[23,0,[\"map\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[28,\"media\",[\"coarsePointer\"],null]],null]],null,{\"statements\":[[0,\"    \"],[5,\"zoom-buttons\",[],[[\"@map\"],[[23,0,[\"map\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ogn-web-viewer/templates/components/map.hbs"
    }
  });

  _exports.default = _default;
});