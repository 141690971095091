define("ogn-web-viewer/router", ["exports", "ogn-web-viewer/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  }); // eslint-disable-next-line array-callback-return

  Router.map(function () {
    this.route('strepla', function () {
      this.route('competition', {
        path: '/:id'
      }, function () {
        this.route('class', {
          path: '/:class_name'
        }, function () {
          this.route('date', {
            path: '/:date'
          }, function () {});
        });
      });
    });
    this.route('404', {
      path: '/*path'
    });

    if (_environment.default.environment !== 'production') {
      this.route('freestyle');
    }
  });
  var _default = Router;
  _exports.default = _default;
});