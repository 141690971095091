define("ogn-web-viewer/components/zoom-buttons", ["exports", "@glimmer/component", "ol/control/Zoom"], function (_exports, _component, _Zoom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {
    constructor() {
      super(...arguments);
      this.map = null;
      this.control = new _Zoom.default();
      this.args.map.addControl(this.control);
    }

    willDestroy() {
      this.args.map.removeControl(this.control);
      super.willDestroy(...arguments);
    }

  }

  _exports.default = _default;
});