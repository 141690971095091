define("ogn-web-viewer/helpers/call-on-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper is roughly similar to the `did-update` render modifier, but
   * does not require an element to run it on. This means it can also be used
   * in situations where the component has no template contents, such as the
   * layer components in this project.
   */
  var _default = Ember.Helper.helper(function callOnUpdate(params) {
    params[0]();
  });

  _exports.default = _default;
});