define("ogn-web-viewer/routes/strepla/competition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    async model({
      id
    }) {
      return await this.store.findRecord('strepla-competition', id);
    }

  }

  _exports.default = _default;
});