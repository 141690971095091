define("ogn-web-viewer/services/scoring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.task = null;
    }

  }

  _exports.default = _default;
});