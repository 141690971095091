define("ogn-web-viewer/styles/components/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "overlay-shadow": "0 3px 6px hsla(0, 0%, 0%, .15), 0 2px 4px hsla(0, 0%, 0%, .12)",
    "spinner-size": "20px",
    "notification": "_notification_jh1wb5"
  };
  _exports.default = _default;
});