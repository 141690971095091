define("ogn-web-viewer/components/score-table", ["exports", "@glimmer/component", "@sentry/browser", "aeroscore/dist/src/format-result", "aeroscore/dist/src/scoring", "aeroscore/dist/src/task/solver/area-task-solver", "aeroscore/dist/src/task/solver/racing-task-solver", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, Sentry, _formatResult, _scoring, _areaTaskSolver, _racingTaskSolver, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let _class = (_dec = Ember.computed('filter.filter'), (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "filter", _descriptor, this);

      _initializerDefineProperty(this, "history", _descriptor2, this);

      this.dayFactors = null;
      this.results = [];

      _initializerDefineProperty(this, "rows", _descriptor3, this);

      _initializerDefineProperty(this, "updateTask", _descriptor4, this);
    }

    get initialDayFactors() {
      return {
        // Task Distance [km]
        // Dt: task.distance / 1000,
        // Minimum Task Time [s]
        // Td: task.options.aatMinTime || 0,
        // Lowest Handicap (H) of all competitors
        Ho: Math.min(...this.filter.filter.map(it => it.handicap)),
        // Minimum Handicapped Distance to validate the Day [km]
        Dm: 100
      };
    }

    update() {
      let results = this.filter.filter.map(filterRow => {
        let fixes = this.history.forId(filterRow.id);
        let solver = this.args.task.options.isAAT ? new _areaTaskSolver.default(this.args.task) : new _racingTaskSolver.default(this.args.task);
        let dayResult, landed, startTimestamp, altitude;

        try {
          solver.consume(fixes);
          let lastFix = fixes[fixes.length - 1];
          altitude = lastFix ? lastFix.altitude : null;
          let result = solver.result;
          landed = false; // TODO

          let start = result.path[0];
          startTimestamp = start && result.distance ? start.time : null; // Competitor’s Handicap, if handicapping is being used; otherwise H=1

          let H = filterRow.handicap / 100;
          dayResult = landed || result.completed || this.args.task.options.isAAT ? (0, _scoring.createInitialDayResult)(result, this.initialDayFactors, H) : (0, _scoring.createIntermediateDayResult)(result, this.initialDayFactors, H, this.args.task, Date.now() / 1000);
        } catch (error) {
          Sentry.captureException(error);
          altitude = null;
          landed = false;
          startTimestamp = null;
          dayResult = {
            completed: false,
            distance: 0,
            time: 0
          };
        }

        return { ...dayResult,
          landed,
          filterRow,
          startTimestamp,
          altitude
        };
      });
      this.dayFactors = (0, _scoring.calculateDayFactors)(results, this.initialDayFactors);
      this.results = results.map(result => (0, _scoring.calculateDayResult)(result, this.dayFactors)).sort(_scoring.compareDayResults);
      this.rows = this.results.map((result, i) => {
        let {
          filterRow
        } = result;
        return {
          num: `${result.landed || result._completed ? ' ' : '!'} ${i + 1}`,
          cn: filterRow.callsign,
          name: filterRow.name,
          type: filterRow.type,
          startTime: result.startTimestamp ? (0, _formatResult.formatTime)(result.startTimestamp) : '',
          time: result._T ? (0, _formatResult.formatDuration)(result._T) : '',
          distance: result._D ? `${result._D.toFixed(1)} km` : '',
          speed: result._V ? `${result._V.toFixed(2)} km/h` : '',
          score: result.S,
          altitude: result.altitude !== null ? `${Math.round(result.altitude)} m` : ''
        };
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "filter", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "history", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "rows", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "initialDayFactors", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "initialDayFactors"), _class2.prototype), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "updateTask", [_emberConcurrencyDecorators.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        while (!Ember.testing) {
          this.update();
          yield (0, _emberConcurrency.timeout)(3000);
        }
      };
    }
  })), _class2));

  _exports.default = _class;
});