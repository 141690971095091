define("ogn-web-viewer/services/map", ["exports", "ol", "ol/control", "ol/interaction", "ol/layer/Tile", "ol/source/OSM", "ol/source/XYZ"], function (_exports, _ol, _control, _interaction, _Tile, _OSM, _XYZ) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  let _class = (_class2 = (_temp = class _class2 extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "media", _descriptor, this);

      this._map = null;
    }

    get map() {
      if (!this._map) {
        let controls = [new _control.ScaleLine(), new _control.Attribution()];
        let layers = [];

        if (!Ember.testing) {
          layers = [new _Tile.default({
            opacity: 0.5,
            source: new _OSM.default({
              crossOrigin: 'anonymous'
            })
          }), new _Tile.default({
            maxResolution: 2500,
            source: new _XYZ.default({
              url: 'https://skylines.aero/mapproxy/tiles/1.0.0/airspace+airports/EPSG3857/{z}/{x}/{y}.png'
            })
          })];
        }

        this._map = new _ol.Map({
          controls,
          keyboardEventTarget: document,
          interactions: (0, _interaction.defaults)({
            altShiftDragRotate: false,
            pinchRotate: false
          }),
          layers,
          view: new _ol.View({
            center: [750998, 6567417],
            zoom: 7
          })
        });
      }

      return this._map;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "media", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);

  _exports.default = _class;
});