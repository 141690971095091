define("ogn-web-viewer/utils/fetch-text", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fetchText;

  async function fetchText(url) {
    let response = await (0, _fetch.default)(url);

    if (response.ok) {
      return response.text();
    }

    throw response;
  }
});