define("ogn-web-viewer/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YWtJM/ZZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"four-oh-four\"],[[\"from\"],[\"ogn-web-viewer/styles/404\"]]]]]],[8],[0,\"\\n  \"],[1,[28,\"svg-jar\",[\"ogn-logo\"],null],false],[0,\"\\n  \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"page-not-found\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ogn-web-viewer/templates/404.hbs"
    }
  });

  _exports.default = _default;
});