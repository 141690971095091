define("ogn-web-viewer/routes/strepla/competition/class/date", ["exports", "ogn-web-viewer/utils/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    async model({
      date
    }) {
      let competition = this.modelFor('strepla.competition');
      let classRecord = this.modelFor('strepla.competition.class');
      let days = await this.store.query('strepla-competition-day', {
        competitionId: competition.id
      });
      let day = days.find(it => it.belongsTo('class').id() === classRecord.id && (0, _date.toDateString)(it.date) === date);

      if (!day) {
        throw new Error(`No competition day matching "${date}" found for the "${classRecord.name}" competition class`);
      }

      return day;
    }

    serialize(model) {
      return {
        date: (0, _date.toDateString)(model.date)
      };
    }

  }

  _exports.default = _default;
});