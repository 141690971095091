define("ogn-web-viewer/sentry", ["exports", "@sentry/browser", "@sentry/integrations/esm/ember", "ogn-web-viewer/config/environment"], function (_exports, Sentry, _ember, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startSentry = startSentry;

  function startSentry() {
    Sentry.init({ ..._environment.default.sentry,
      integrations: [new _ember.Ember()],

      beforeSend(event, hint) {
        let error = hint.originalException;

        if (error) {
          // ignore aborted route transitions from the Ember.js router
          if (error.name === 'TransitionAborted') {
            return null;
          } // ignore aborted requests from ember-fetch


          if (error.name === 'AbortError') {
            return null;
          }
        }

        return event;
      }

    });
  }
});