define("ogn-web-viewer/styles/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "four-oh-four": "_four-oh-four_1huu0r"
  };
  _exports.default = _default;
});