define("ogn-web-viewer/utils/hash-to-qp", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hashToQueryParams = hashToQueryParams;

  /**
   * Converts the GliderTracker-style hash params (`lst` and `tsk`) to
   * query parameters.
   */
  function hashToQueryParams() {
    let {
      hash,
      search
    } = _emberWindowMock.default.location;
    if (hash[0] !== '#') return;
    let hashParams = new URLSearchParams(hash.substring(1));
    let searchParams = new URLSearchParams(search);

    if (hashParams.has('lst')) {
      let lst = hashParams.get('lst');
      searchParams.set('lst', lst);
      hashParams.delete('lst');
    }

    if (hashParams.has('tsk')) {
      let tsk = hashParams.get('tsk');
      searchParams.set('tsk', tsk);
      hashParams.delete('tsk');
    }

    let newHash = hashParams.toString();

    if (newHash) {
      newHash = `#${newHash}`;
    }

    let newSearch = searchParams.toString();

    if (newSearch) {
      newSearch = `?${newSearch}`;
    }

    _emberWindowMock.default.location.search = newSearch;
    _emberWindowMock.default.location.hash = newHash;
  }
});