define("ogn-web-viewer/components/fullscreen-button", ["exports", "@glimmer/component", "ol/control"], function (_exports, _component, _control) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {
    constructor() {
      super(...arguments);
      this.map = null;
      this.control = new _control.FullScreen();
      this.args.map.addControl(this.control);
    }

    willDestroy() {
      this.args.map.removeControl(this.control);
      super.willDestroy();
    }

  }

  _exports.default = _default;
});