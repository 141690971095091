define("ogn-web-viewer/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c9OPLRU1",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"head-layout\"],false],[0,\"\\n\"],[1,[28,\"page-title\",[\"OGN WebViewer\"],null],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"onlyOutlet\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"map\",[],[[\"@task\"],[[23,0,[\"scoring\",\"task\"]]]]],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"hasDeviceFilter\"]],[23,0,[\"scoring\",\"task\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"scores-wrapper\"],[10,\"style\",\"height: 30%; overflow-y: scroll;\"],[8],[0,\"\\n      \"],[5,\"score-table\",[],[[\"@task\"],[[23,0,[\"scoring\",\"task\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ogn-web-viewer/templates/application.hbs"
    }
  });

  _exports.default = _default;
});