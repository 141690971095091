define("ogn-web-viewer/adapters/strepla-competitor", ["exports", "ember-data-strepla/urls", "ogn-web-viewer/adapters/-base"], function (_exports, _urls, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _base.default {
    async query(store, type, query) {
      (false && !(type.modelName === 'strepla-competitor') && Ember.assert('Query `type` must be `strepla-competitor`', type.modelName === 'strepla-competitor'));
      let {
        competitionId
      } = query;
      (false && !(competitionId) && Ember.assert('Query must have a `competitionId` property', competitionId));
      let competitors = await this._request(`${_urls.COMPETITOR_LIST_URL}&cID=${competitionId}`);

      if (query.className) {
        competitors = competitors.filter(it => it.className === query.className);
      }

      return competitors.map(it => ({ ...it,
        competitionId
      }));
    }

  }

  _exports.default = _default;
});