define("ogn-web-viewer/templates/components/task-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FAfiM115",
    "block": "{\"symbols\":[\"@task\"],\"statements\":[[1,[28,\"call-on-update\",[[23,0,[\"updateSource\"]],[23,1,[]]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ogn-web-viewer/templates/components/task-layer.hbs"
    }
  });

  _exports.default = _default;
});