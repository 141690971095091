define("ogn-web-viewer/routes/strepla/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    async model() {// TODO download the list of available competitions and show it to the user
    }

  }

  _exports.default = _default;
});