define("ogn-web-viewer/routes/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    activate() {
      super.activate(...arguments);
      this.controllerFor('application').set('onlyOutlet', true);
    }

    deactivate() {
      super.deactivate(...arguments);
      this.controllerFor('application').set('onlyOutlet', false);
    }

  }

  _exports.default = _default;
});