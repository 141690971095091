define("ogn-web-viewer/transforms/strepla-date", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;

  class _default extends Transform {
    deserialize(serialized) {
      let time = Date.parse(serialized);

      if (isNaN(time)) {
        throw new Error(`Invalid date: ${serialized}`);
      }

      return new Date(time);
    }

  }

  _exports.default = _default;
});