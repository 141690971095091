define("ogn-web-viewer/routes/index", ["exports", "ol/extent", "ol/proj", "ogn-web-viewer/utils/fetch-text", "ogn-web-viewer/utils/normalize-device-id"], function (_exports, _extent, _proj, _fetchText, _normalizeDeviceId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and set to use loose mode. ' + 'To use proposal-class-properties in spec mode with decorators, wait for ' + 'the next major version of decorators in stage 2.'); }

  const EPSG_4326 = 'EPSG:4326';
  const EPSG_3857 = 'EPSG:3857';

  let _class = (_dec = Ember.inject.service('map'), (_class2 = (_temp = class _class2 extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "filter", _descriptor, this);

      _initializerDefineProperty(this, "scoring", _descriptor2, this);

      _initializerDefineProperty(this, "history", _descriptor3, this);

      _initializerDefineProperty(this, "ws", _descriptor4, this);

      _initializerDefineProperty(this, "mapService", _descriptor5, this);
    }

    async model(params, transition) {
      let {
        queryParams
      } = transition.to;
      return Promise.all([this.loadDeviceFilter(queryParams.lst), this.loadTask(queryParams.tsk)]);
    }

    setupController(controller, [filter, task]) {
      if (filter.length !== 0) {
        let records = filter.map(row => {
          let id = (0, _normalizeDeviceId.normalizeDeviceId)(row.ID) || row.ID;
          let name = row.NAME;
          let registration = row.CALL;
          let callsign = row.CN;
          let type = row.TYPE;
          let handicap = 'HANDICAP' in row ? parseFloat(row.HANDICAP) : 1.0;
          return {
            id,
            name,
            registration,
            callsign,
            type,
            handicap
          };
        });
        Ember.run(() => this.filter.add(...records));

        for (let record of records) {
          this.ws.subscribeToId(record.id);
        }

        this.history.loadForIds(...records.map(record => record.id));
      }

      Ember.run(() => this.scoring.set('task', task));
      this.mapService.map.updateSize();

      if (task) {
        // zoom map in on the task area
        let bbox = task.bbox.slice();
        (0, _extent.scaleFromCenter)(bbox, 0.3);
        let extent = (0, _proj.transformExtent)(bbox, EPSG_4326, EPSG_3857);
        setTimeout(() => this.mapService.map.getView().fit(extent, {
          duration: 1000
        }), 100);
      }
    }

    async loadDeviceFilter(url) {
      if (url) {
        let [text, {
          default: neatCSV
        }] = await Promise.all([(0, _fetchText.default)(url), emberAutoImportDynamic("neat-csv")]);
        return await neatCSV(text);
      }

      return [];
    }

    async loadTask(url) {
      if (url) {
        let [text, {
          readTaskFromString
        }] = await Promise.all([(0, _fetchText.default)(url), emberAutoImportDynamic("aeroscore/dist/src/read-task")]);

        try {
          return readTaskFromString(text);
        } catch (cause) {
          throw new InvalidTaskError({
            url,
            cause
          });
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "filter", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "scoring", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "history", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "ws", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "mapService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;

  class InvalidTaskError extends Error {
    constructor({
      url,
      cause
    }) {
      super(cause.message);
      this.name = 'InvalidTaskError';
      this.cause = cause;
      this.url = url;
    }

  }
});