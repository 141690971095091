define("ogn-web-viewer/routes/strepla/competition/class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    async model({
      class_name: className
    }) {
      let competition = this.modelFor('strepla.competition');
      let classes = await this.store.query('strepla-competition-class', {
        competitionId: competition.id
      });
      let classRecord = classes.find(it => it.name === className);

      if (!classRecord) {
        throw new Error(`Competition class ${className} could not be found`);
      }

      return classRecord;
    }

  }

  _exports.default = _default;
});