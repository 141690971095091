define("ogn-web-viewer/services/intl", ["exports", "@fluent/langneg", "@sentry/browser", "ember-intl/services/intl", "fetch", "ogn-web-viewer/config/environment"], function (_exports, _langneg, Sentry, _intl, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _intl.default {
    negotiateLocale() {
      let requestedLocales = navigator.languages || [navigator.language || navigator.userLanguage].filter(Boolean);
      return (0, _langneg.negotiateLanguages)(requestedLocales, _environment.default.intl.locales, {
        strategy: 'lookup',
        defaultLocale: 'en'
      })[0];
    }

    async loadTranslations(locale) {
      let response = await (0, _fetch.default)(`/translations/${locale.toLowerCase()}.json`);
      let translations = await response.json();
      this.addTranslations(locale, translations);
    }

    setLocale(locale) {
      super.setLocale(...arguments);
      Sentry.configureScope(scope => scope.setTag('locale', locale));
    }

  }

  _exports.default = _default;
});