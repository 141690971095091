define("ogn-web-viewer/templates/components/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6ytMVSeY",
    "block": "{\"symbols\":[\"&attrs\",\"@text\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"notification\"],[[\"from\"],[\"ogn-web-viewer/styles/components/notification\"]]]]]],[13,1],[8],[0,\"\\n  \"],[1,[28,\"svg-jar\",[\"ogn-logo-animated\"],null],false],[0,\"\\n  \"],[7,\"span\",true],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ogn-web-viewer/templates/components/notification.hbs"
    }
  });

  _exports.default = _default;
});