define("ogn-web-viewer/serializers/-base", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Serializer
  } = _emberData.default;

  class _default extends Serializer {
    applyTransforms(typeClass, data) {
      let attributes = typeClass.attributes;
      typeClass.eachTransformedAttribute((key, typeClass) => {
        if (data[key] !== undefined) {
          let transform = this.transformFor(typeClass);
          let transformMeta = attributes.get(key);
          data[key] = transform.deserialize(data[key], transformMeta.options);
        }
      });
      return data;
    }

    transformFor(attributeType) {
      let transform = Ember.getOwner(this).lookup(`transform:${attributeType}`);
      (false && !(transform) && Ember.assert(`Unable to find the transform for \`attr('${attributeType}')\``, transform));
      return transform;
    }

  }

  _exports.default = _default;
});